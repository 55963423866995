import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";

@Injectable()
export class PublisherService {
  private readonly apiPrefix = '/client/publishers'

  constructor(
    private api: ApiService,
  ) {
  }

  getPublishers(queryString = '') {
    return this.api.get(`${this.apiPrefix}${queryString}`);
  }

  getPublisherWorkbooks(publisherId: number, queryString = '') {
    return this.api.get(`${this.apiPrefix}/${publisherId}/workbooks${queryString}`);
  }
}
